// src/App.js

import React from 'react';
import './App.css'; // Optional: Import CSS file for styling
import S3FileList from './S3FileList'; // Import S3FileList component

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h6>MUSICDJ PLAYLIST GENERATOR</h6>
      </header>
      <main>
        <S3FileList />
      </main>
    </div>
  );
}

export default App;
