import React, { useState, useEffect, useRef } from 'react';
import AWS from 'aws-sdk';
import { FaMinus, FaCopy, FaTrash, FaSyncAlt, FaPlus, FaTrashAlt, FaList } from 'react-icons/fa';
import './S3FileList.css';

const S3FileList = () => {
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [currentPrefix, setCurrentPrefix] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [playlist, setPlaylist] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [shufflePlaylist, setShufflePlaylist] = useState(false);
  const [deletingObject, setDeletingObject] = useState(null);
  const [creatingFolder, setCreatingFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [playlistFormat, setPlaylistFormat] = useState('m3u');
  const [playlistName, setPlaylistName] = useState('playlist');
  const [mediaPlayerRef, setMediaPlayerRef] = useState(null);
  const isPlayingRef = useRef(false);
  const [dragOver, setDragOver] = useState(false);
  const [draggedOverItem, setDraggedOverItem] = useState(null);
  const [numRandomFiles, setNumRandomFiles] = useState(5); // Default number of random files

  useEffect(() => {
    loadObjects(); // Initial load of objects
  }, [currentPrefix]);

  useEffect(() => {
    const numSongs = playlist.length;
    document.title = numSongs > 0 ? `Playlist (${numSongs})` : 'Playlist';
  }, [playlist]);

  useEffect(() => {
    setMediaPlayerRef(document.createElement('audio'));
  }, []);

  const loadObjects = () => {
    AWS.config.update({
      region: 'eu-central-1',
      accessKeyId: 'AKIATZSAU6NUHSYYS3OS',
      secretAccessKey: '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',
    });

    const s3 = new AWS.S3();
    const params = {
      Bucket: 'musicdj',
      Delimiter: '/',
      Prefix: currentPrefix,
    };

    s3.listObjectsV2(params, (err, data) => {
      if (err) {
        console.log('Error', err);
      } else {
        const updatedBreadcrumbs = [];
        let currentPath = '';
        const parts = currentPrefix.split('/').filter(Boolean);
        parts.forEach((part, index) => {
          currentPath += `${part}/`;
          updatedBreadcrumbs.push({ name: part, prefix: currentPath });
        });

        const subFolders = data.CommonPrefixes.map(prefixObj => ({
          type: 'folder',
          name: prefixObj.Prefix.split('/').filter(Boolean).pop(),
          prefix: prefixObj.Prefix,
        }));
        const bucketFiles = data.Contents.filter(file => file.Key !== params.Prefix);

        setFolders(subFolders);
        setFiles(bucketFiles);
        setFilteredFiles(bucketFiles);
        setBreadcrumbs(updatedBreadcrumbs);
      }
    });
  };

  const handleFolderClick = (prefix) => {
    setCurrentPrefix(prefix);
    setSelectedFile(null);
  };

  const handleBreadcrumbClick = (prefix) => {
    setCurrentPrefix(prefix);
    setSelectedFile(null);
  };

  const handleFileClick = (fileKey) => {
    setSelectedFile(fileKey);
    playMedia(fileKey);
  };

  const playMedia = (fileKey) => {
    const media = mediaPlayerRef.current;
    media.pause();
    media.currentTime = 0;
    media.src = `https://musicdj.s3.eu-central-1.amazonaws.com/${fileKey}`;

    media.load();
    media.play().then(() => {
      isPlayingRef.current = true;
    }).catch(error => {
      console.error('Failed to play media:', error);
    });
  };

  const handleAddToPlaylist = (fileKey) => {
    if (!playlist.includes(fileKey)) {
      setPlaylist(prevPlaylist => [...prevPlaylist, fileKey]);
    }
  };

  const handleAddAllFilesToPlaylist = () => {
    const allFileKeys = files.map(file => file.Key);
    setPlaylist(prevPlaylist => [...new Set([...prevPlaylist, ...allFileKeys])]);
  };

  const handleAddRandomFilesToPlaylist = () => {
    const allFileKeys = files.map(file => file.Key);
    const randomFileKeys = getRandomItems(allFileKeys, numRandomFiles);
    setPlaylist(prevPlaylist => [...new Set([...prevPlaylist, ...randomFileKeys])]);
  };

  const getRandomItems = (array, count) => {
    const shuffledArray = array.sort(() => Math.random() - 0.5);
    return shuffledArray.slice(0, count);
  };

  const handleDragStart = (event, fileKey) => {
    event.dataTransfer.setData('text/plain', fileKey);
    setDraggedItem(fileKey);
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
  };

  const handleDragOverPlaylist = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragEnterPlaylist = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeavePlaylist = () => {
    setDragOver(false);
  };

  const handleDropOnPlaylist = (event) => {
    event.preventDefault();
    const fileKey = event.dataTransfer.getData('text/plain');
    handleAddToPlaylist(fileKey);
    setDraggedItem(null);
    setDragOver(false);
  };

  const handleSavePlaylist = async () => {
    let playlistToSave = [...playlist];

    if (shufflePlaylist) {
      playlistToSave = shuffleArray(playlistToSave);
    }

    let playlistContent = `#EXTM3U\n`;

    playlistToSave.forEach((fileKey) => {
      const fileName = fileKey.split('/').pop();
      playlistContent += `#EXTINF:-1,${fileName}\n`;
      playlistContent += `https://musicdj.s3.eu-central-1.amazonaws.com/${fileKey}\n`;
    });

    const fileExtension = playlistFormat === 'm3u' ? 'm3u' : 'm3u8';
    const fileName = `${playlistName}.${fileExtension}`;

    const blob = new Blob([playlistContent], { type: 'audio/x-mpegurl' });

    const uploadParams = {
      Bucket: 'musicdj',
      Key: `audio-playlist/${fileName}`,
      Body: blob,
      ContentType: 'audio/x-mpegurl'
    };

    try {
      const s3 = new AWS.S3();
      await s3.upload(uploadParams).promise();
      alert('Playlist saved successfully!');
    } catch (error) {
      console.error('Error saving playlist:', error);
      alert('Failed to save playlist. Please try again.');
    }
  };

  const handlePlaylistItemClick = (fileKey) => {
    setSelectedFile(fileKey);
    playMedia(fileKey);
  };

  const handleRemoveFromPlaylist = (index) => {
    setPlaylist(prevPlaylist => prevPlaylist.filter((_, i) => i !== index));
  };

  const handleRemoveAllFromPlaylist = () => {
    if (window.confirm('Are you sure you want to delete all songs from the playlist?')) {
      setPlaylist([]);
    }
  };

  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  const handleShufflePlaylist = () => {
    setShufflePlaylist(prevState => !prevState);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterFiles(event.target.value);
  };

  const filterFiles = (term) => {
    if (term.trim() === '') {
      setFilteredFiles(files);
    } else {
      const filtered = files.filter(file => file.Key.toLowerCase().includes(term.toLowerCase()));
      setFilteredFiles(filtered);
    }
  };

  const handleCopyObjectUrl = (fileKey) => {
    const url = `https://musicdj.s3.eu-central-1.amazonaws.com/${fileKey}`;
    navigator.clipboard.writeText(url).then(() => {
      alert('Object URL copied to clipboard');
    }).catch(error => {
      console.error('Error copying object URL:', error);
      alert('Failed to copy object URL. Please try again.');
    });
  };

  const handleDeleteObject = async (fileKey) => {
    if (!window.confirm('Are you sure you want to delete this object?')) return;

    try {
      setDeletingObject(fileKey);
      const s3 = new AWS.S3();
      await s3.deleteObject({ Bucket: 'musicdj', Key: fileKey }).promise();
      setDeletingObject(null);
      alert('Object deleted successfully!');
      setCurrentPrefix(currentPrefix); // Refresh file list after deletion
    } catch (error) {
      console.error('Error deleting object:', error);
      alert('Failed to delete object. Please try again.');
    }
  };

  const handleCreateFolder = async () => {
    if (!newFolderName) {
      alert('Please enter a folder name.');
      return;
    }

    try {
      setCreatingFolder(true);
      const s3 = new AWS.S3();
      const folderKey = `${currentPrefix}${newFolderName}/`;
      await s3.putObject({ Bucket: 'musicdj', Key: folderKey }).promise();
      setCreatingFolder(false);
      alert(`Folder "${newFolderName}" created successfully!`);
      setCurrentPrefix(currentPrefix); // Refresh file list after creation
      setNewFolderName('');
    } catch (error) {
      console.error('Error creating folder:', error);
      alert('Failed to create folder. Please try again.');
    }
  };

  const handleRefreshStorage = () => {
    setCurrentPrefix(''); // Refresh storage by resetting current prefix
  };

  return (
    <div className="s3-file-list">
      <div className="left-panel" onDragOver={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()}>
        <div className="file-list">
          <div className="controls">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button onClick={handleRefreshStorage} disabled={deletingObject !== null}>
              <FaSyncAlt /> Refresh
            </button>
            <button onClick={handleAddAllFilesToPlaylist} disabled={filteredFiles.length === 0}>
              <FaList /> Add All Files
            </button>
            <button onClick={handleAddRandomFilesToPlaylist} disabled={filteredFiles.length === 0}>
              <FaPlus /> Add Random Files
            </button>
          </div>
          <table>
            <tbody>
              {currentPrefix && (
                <tr className="folder-row" onClick={() => handleFolderClick('')}>
                  <td><FaMinus className="folder-icon" /></td>
                  <td></td> {/* Empty cell for spacing */}
                  <td>...</td>
                </tr>
              )}
              {breadcrumbs.map((folder, index) => (
                <tr key={`breadcrumb-${index}`} className="folder-row" onClick={() => handleBreadcrumbClick(folder.prefix)}>
                  <td><FaMinus className="folder-icon" /></td>
                  <td></td> {/* Empty cell for spacing */}
                  <td>{folder.name}</td>
                </tr>
              ))}
              {folders.map((folder, index) => (
                <tr key={`folder-${index}`} className="folder-row" onClick={() => handleFolderClick(folder.prefix)}>
                  <td><FaMinus className="folder-icon" /></td>
                  <td></td> {/* Empty cell for spacing */}
                  <td>{folder.name}</td>
                </tr>
              ))}
              {filteredFiles.map((file, index) => (
                <tr
                  key={`file-${index}`}
                  className={selectedFile === file.Key ? 'file-row selected' : 'file-row'}
                  onClick={() => handleFileClick(file.Key)}
                  draggable
                  onDragStart={(event) => handleDragStart(event, file.Key)}
                  onDragEnd={handleDragEnd}
                >
                  <td>{file.Key.split('/').pop()}</td>
                  <td>
                    <FaCopy
                      className="copy-icon"
                      title="Copy Object URL"
                      onClick={() => handleCopyObjectUrl(file.Key)}
                    />
                    <FaTrash
                      className="delete-icon"
                      title="Delete Object"
                      onClick={() => handleDeleteObject(file.Key)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="create-folder">
          <h3>Create New Folder</h3>
          <input
            type="text"
            placeholder="Enter folder name"
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
          />
          <button onClick={handleCreateFolder} disabled={creatingFolder}>
            Create Folder
          </button>
        </div>
      </div>
      <div className="right-panel">
        <div
          className={`playlist ${dragOver && 'drag-over'}`}
          onDragOver={(e) => handleDragOverPlaylist(e)}
          onDragEnter={(e) => handleDragEnterPlaylist(e)}
          onDragLeave={handleDragLeavePlaylist}
          onDrop={(e) => handleDropOnPlaylist(e)}
        >
          <h2>Playlist</h2>
          {mediaPlayerRef && <audio ref={mediaPlayerRef} controls />}
          <div className="playlist-options">
            <div className="playlist-options-row">
              <label htmlFor="playlistFormat">Format:</label>
              <select id="playlistFormat" value={playlistFormat} onChange={(e) => setPlaylistFormat(e.target.value)}>
                <option value="m3u">M3U</option>
                <option value="m3u8">M3U8</option>
              </select>
            </div>
            <div className="playlist-options-row">
              <label htmlFor="playlistName">Name:</label>
              <input
                type="text"
                id="playlistName"
                value={playlistName}
                onChange={(e) => setPlaylistName(e.target.value)}
              />
            </div>
            <div className="playlist-options-row">
              <label htmlFor="saveLocation">Save Location:</label>
              <select id="saveLocation">
                <option value="audio-playlist">Audio Playlist</option>
                <option value="video-playlist">Video Playlist</option>
              </select>
            </div>
            <div className="playlist-options-row">
              <label htmlFor="shufflePlaylist">Shuffle:</label>
              <input
                type="checkbox"
                id="shufflePlaylist"
                checked={shufflePlaylist}
                onChange={handleShufflePlaylist}
              />
            </div>
            <div className="playlist-options-row">
              <button onClick={handleSavePlaylist}>Save Playlist</button>
              <button onClick={handleRemoveAllFromPlaylist} className="delete-all-button">
                <FaTrashAlt /> Delete All
              </button>
            </div>
          </div>
          <div className="playlist-summary">
            <p>Number of Songs: {playlist.length}</p>
          </div>
          <table>
            <thead>
              <tr>
                <th>File Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {playlist.map((fileKey, index) => (
                <tr
                  key={`playlist-item-${index}`}
                  className={draggedOverItem === index ? 'playlist-item drag-over' : 'playlist-item'}
                  onClick={() => handlePlaylistItemClick(fileKey)}
                  draggable
                  onDragStart={(event) => handleDragStart(event, fileKey)}
                  onDragEnd={handleDragEnd}
                >
                  <td>{fileKey.split('/').pop()}</td>
                  <td>
                    <FaTrash
                      className="delete-icon"
                      title="Remove from Playlist"
                      onClick={() => handleRemoveFromPlaylist(index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default S3FileList;
